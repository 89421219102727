import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import WorkTogether from "../components/WorkTogether/WorkTogether";
import PageHeader from "../components/PageHeader/PageHeader";
import ServiceDescriptionCard from "../components/Services/ServiceDescriptionCard";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

export default function ServicesPage({ data: {
  markdownRemark: {
    frontmatter: {
      title,
      description,
      items
    }
  },
}}) {
  return (
    <Layout>
      <Helmet title={`${title} | ${config.siteTitle}`} />
      <SEO />
      <Header />
      <PageHeader
        title={title}
        content={description}
        backUrl="/services"
      />
      <section className="container bg-white characteristics-section">
        <div className="row d-flex justify-content-center py-5">
          {items.map((item, index) => <ServiceDescriptionCard key={index} data={item} />)}
        </div>
      </section>
      <WorkTogether />
      <Footer />
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ServicePostById($id: String!) {
    markdownRemark(frontmatter: { id: { eq: $id } }) {
      frontmatter {
        id
        title
        description
        items {
          title
          keywords
          description
        }
      }
    }
  }
`;
