import React from "react";

export default function ServiceDescriptionCard({ data: { title, description, keywords } }) {
  return (
    <div className="col-12 col-sm-10 col-md-6 col-lg-4 mb-4">
      <div className="service-description-card h-100">
        <div className="d-flex justify-content-center mb-5">
          <div className="hexagon" />
        </div>
        <div>
          <h4 className="mb-4">{title}</h4>
        </div>
        <div>
          <p>
            <span>{description}</span>
          </p>
          {keywords && keywords.map(word => (
            <p className="mb-0">
              <i>{`#${word}`}</i>
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
