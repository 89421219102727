import React from "react";
import { Link } from "gatsby";
import robotImg from "../../assets/images/full-robot.png";

export default function WorkTogether() {
  return (
    <section className="container-fluid bg-gradient-blueberry-lavender worktogether-section color-white">
      <div className="container py-3">
        <div className="row py-3">
          <div className="col-12 col-md-6">
            <img src={robotImg} className="d-inline-block align-middle img-fluid" alt="Toptive robot" />
          </div>
          <div className="col-12 col-md-6 py-5 d-flex flex-column justify-content-center">
            <h3 className="mb-4">
              Work together.
            </h3>
            <p className="mb-4">
              A unique expert team with the most cutting-edge practices and technologies results in an outstanding experience of service where engineering and design integrate to meet the next level.
            </p>
            <Link to="/contact" className="btn btn-outline-white color-blueberry mt-3">
              Let&apos;s start a conversation
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
